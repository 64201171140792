@font-face {
    font-family: 'Roboto-Black';
    src: local('Roboto-Black'), url(../fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local('Roboto-Bold'), url(../fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: local('Roboto-Regular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto-Medium'), url(../fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Light';
    src: local('Roboto-Light'), url(../fonts/Roboto-Light.ttf) format('truetype');
}

h1, h2 {
    font-family: 'Roboto-Bold';
}

h2 {
    color: #2C62CD;
}

button:focus {
    outline: 0
}

.list-sidebar-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    padding-right: 30px;
    transition: 150ms;
}

.list-sidebar-content:hover {
    color: lightskyblue;
}

.list-sidebar-content.with-icon {
    margin-left: 10px;
}

.list-sidebar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 52px;

}

.list-subsidebar {
    background: #044C92;
    /* background: #f5bc5e; */
    /* background: #72c749; */
}

.list-sidebar-active {
    background:#022f5a;
    /* background: #f5bc5e; */
    /* background: #92d36f; */
}

.btn-main {
    border: 1px solid #2C62CD;
    color: #2C62CD;
}

.btn-save {
    background-color: #2C62CD;
    color: #FFF;
}

.btn-main:hover {
    border: 1px solid #2C62CD;
    color: #2C62CD;
    background-color: transparent;
}

.table-head {
    background-color: #FFF1C5;
    color: #272B30;
}

.blue-main {
    color: #2C62CD;
    font-weight: bold;
}

.modal-main header {
    display: none;
}

.table-container table {
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: unset;
}

.table-container thead {
    font-size: 1rem;
    font-weight: 600;
}

.table-container.freeze-header-500 > div:first-child {
    max-height: 500px;
}

.table-container.freeze-header-500 thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.image-title{
    align-items: left;
}
